import { useMemo } from 'react';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

export const useHasUnsentItems = () => {
  const { data: order } = useOrder();
  const hasUnsentItems = useMemo(() => {
    return !!order?.items?.find((item) => item?.sentDateTime === null);
  }, [order]);
  return hasUnsentItems;
};
