import {
  useScreenSizeState,
  useCustomerState,
  useModalDispatch,
  useCheckoutState,
  useGlobalUIState,
  useGlobalUIDispatch,
  useSavedDineInContextState,
} from '@olo-web/client-state';
import { useMemo, useCallback, useEffect } from 'react';
import { EAnalyticsEventNames, ECheckoutTypes, EModalTypes } from '@olo-web/types/enums';
import { calculateOrderTotal } from '@olo-web/domain/orders/functions/calculateOrderTotal';
import { useGoToCheckoutPage } from '@olo-web/utils/common/hooks/useGoToCheckoutPage';
import { orderHasValidationIssues } from '@olo-web/domain/orders/functions/orderHasValidationIssues';
import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useIsOverMaxOrderAmount } from '@olo-web/domain/orders/hooks/useIsOverMaxOrderAmount';
import { useIsMinOrderAmountUnmet } from '@olo-web/domain/orders/hooks/useIsMinOrderAmountUnmet';
import { useValidateOrder } from '@olo-web/domain/orders/mutations/useValidateOrder';
import {
  useDeliveryModal,
  useKeyPress,
  useSendEvent,
  useCurrentOrderTips,
  useOrderType,
  useGoToCheckoutOptionsPage,
  useToast,
  useIsDineIn,
  useGoToMenu,
  useIsSplitByAmount,
} from '@olo-web/utils/common/hooks';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useRouter } from 'next/router';
import { usePaymentMethods } from '@templates/Checkout/common/hooks/usePaymentMethods';
import { useHasUnsentItems } from './useHasUnsentItems';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { EGALocations } from '@olo-web/types/enums/googleAnalyticsLocations.enum';
import { useMerchantOrderTypeDetails } from '@domain/merchants/queries/useMerchantOrderTypeDetails';
import { useValidateAndSendItems } from './useValidateAndSendItems';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions/isFeatureEnabledForMerchant';
import { useFeatureFlags } from '@domain';
import { EOrderErrorsCode } from '@olo-web/types/enums/orderErrorsCode.enum';

const deliveryAmountUnmetToastId = 'delivery-min-amount-unmet';
const orderAmountToastId = 'max-order-amount-surpassed';
const tipToastId = 'no-tip-selected';

export const useBagBar = () => {
  const { bagOpen } = useGlobalUIState();
  const globalUIDispatch = useGlobalUIDispatch();
  const { data: merchant, refetch: fetchMerchant } = useMerchant();
  const { data: order, isLoading: orderLoading, refetch: refetchOrder } = useOrder();
  const { isMd, isLg } = useScreenSizeState();
  const { shouldShowDeliveryModal, openDeliveryModal } = useDeliveryModal();
  const router = useRouter();
  const modalDispatch = useModalDispatch();
  const { routerLoading } = useGlobalUIState();
  const { selectedTips } = useCurrentOrderTips();
  const { notify, toast } = useToast();
  const escapePressed = useKeyPress('Escape');
  const { mutateAsync: validateOrder, isLoading: isValidatingBagBar, reset } = useValidateOrder();
  const isOverMaxOrderAmount = useIsOverMaxOrderAmount();
  const isMinOrderAmountUnmet = useIsMinOrderAmountUnmet();
  const { sendEvent } = useSendEvent({ canRepeat: true });
  const orderType = useOrderType();
  const goToCheckoutPage = useGoToCheckoutPage();
  const { paymentMethods } = usePaymentMethods();
  const isDinein = useIsDineIn();
  const { previousPaymentType } = useCustomerState();
  const hasUnsentItems = useHasUnsentItems();
  const savedDineInState = useSavedDineInContextState();
  const goToCheckoutOptionsPage = useGoToCheckoutOptionsPage();
  const isSplitByAmount = useIsSplitByAmount();
  const checkoutState = useCheckoutState();
  const { data: orderTypeDetails } = useMerchantOrderTypeDetails();
  const goToMenu = useGoToMenu({ resetOrderStatus: false });
  const isOrderPending = order?.groupOrderInfo?.status === EOrderStatus.PENDING;
  const { validateAndSendItems, isSendingItems, isValidatingOrder } = useValidateAndSendItems();
  const { data: featureFlags } = useFeatureFlags();

  const isHandleAsapLogicOnServer = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: featureFlags?.handleAsapLogicOnServer?.on,
    allowList: featureFlags?.handleAsapLogicOnServer?.allow,
    denyList: featureFlags?.handleAsapLogicOnServer?.deny,
  });

  const handleOpen = useCallback(() => {
    if (isDinein) {
      refetchOrder();
    }

    globalUIDispatch({ type: 'OPEN_BAG' });
  }, [globalUIDispatch, isDinein, refetchOrder]);

  const handleClose = useCallback(() => {
    globalUIDispatch({ type: 'CLOSE_BAG' });
  }, [globalUIDispatch]);

  const handleToggle = useCallback(() => {
    return bagOpen ? handleClose() : handleOpen();
  }, [bagOpen, handleClose, handleOpen]);

  useEffect(() => {
    if (selectedTips) toast.close(tipToastId);
    if (!isOverMaxOrderAmount) toast.close(orderAmountToastId);
  }, [selectedTips, toast, isOverMaxOrderAmount]);

  useEffect(() => {
    if (bagOpen && escapePressed) handleClose();
  }, [escapePressed, bagOpen, handleClose]);

  const goToCheckout = async () => {
    if (shouldShowDeliveryModal) {
      openDeliveryModal({ showNeedAddressWarning: true });
      return;
    }
    const orderTypeIds = await fetchMerchant().then((data) => data.data.orderTypeIds);
    if (!Object.values(orderTypeIds).includes(order?.orderTypeId)) {
      return;
    }

    if (isMinOrderAmountUnmet) {
      if (!toast.isActive(deliveryAmountUnmetToastId)) {
        notify({
          id: deliveryAmountUnmetToastId,
          title: `Minimum delivery order amount not met (${formatPrice(
            Number(merchant?.orderTypes?.[orderType]?.minOrderAmount || 0)
          )})`,
          description: `Please add more items or switch to pickup`,
          status: 'error',
          variant: 'left-accent',
          position: isMd ? 'top-right' : 'top',
          isClosable: true,
        });
      }
      return;
    }

    if (isOverMaxOrderAmount) {
      if (!toast.isActive(orderAmountToastId)) {
        notify({
          id: orderAmountToastId,
          title: `Sorry, but we can only accept orders of ${formatPrice(
            merchant?.maxOrderAmount || 0
          )} or less`,
          description: `Please give us a call to place a large order`,
          status: 'error',
          variant: 'left-accent',
          position: isMd ? 'top-right' : 'top',
          isClosable: true,
        });
      }
      return;
    }
    if (isDinein) {
      const isInCheckout = order?.groupOrderInfo?.status === EOrderStatus.IN_CHECKOUT;
      const isSplitting = order?.groupOrderInfo?.status === EOrderStatus.SPLITTING_CHECK;
      const isInitiator = order?.groupOrderInfo?.statusGuestId === savedDineInState?.guest?.id;

      refetchOrder();

      if (isInCheckout && !isInitiator) {
        modalDispatch({ type: 'OPEN_MODAL', payload: { modalKey: EModalTypes.TABLE_CHECKOUT } });
        sendEvent(EAnalyticsEventNames.LOCKED_CHECKOUT, {
          googleAnalytics: {
            location: EGALocations.DINE_IN,
            action: 'click',
            object: EAnalyticsEventNames.LOCKED_CHECKOUT,
            eventMetadata: {
              initiator: savedDineInState?.guest,
              dineInType: merchant?.dineinPayLaterEnabled ? 'Pay Later' : 'Pay Now',
            },
          },
        });
        return;
      } else if (isSplitting && !isInitiator) {
        modalDispatch({ type: 'OPEN_MODAL', payload: { modalKey: EModalTypes.TABLE_CHECKOUT } });
        sendEvent(EAnalyticsEventNames.LOCKED_CHECKOUT, {
          googleAnalytics: {
            location: EGALocations.DINE_IN,
            action: 'click',
            object: EAnalyticsEventNames.LOCKED_CHECKOUT,
            eventMetadata: {
              initiator: savedDineInState?.guest,
              dineInType: merchant?.dineinPayLaterEnabled ? 'Pay Later' : 'Pay Now',
            },
          },
        });
        return;
      }
      if (merchant?.dineinPayLaterEnabled) {
        if (hasUnsentItems) {
          if (merchant?.dineinpreauthrequired && !order?.paymentAuth) {
            modalDispatch({
              type: 'OPEN_MODAL',
              payload: {
                modalKey: EModalTypes.START_A_TAB,
                modalProps: { closeOnOverlayClick: false, closeOnEsc: false },
              },
            });
            return;
          }
          await validateAndSendItems();
          return;
        }
      }
      sendEvent(EAnalyticsEventNames.ORDER_LOCKED, {
        googleAnalytics: {
          location: EGALocations.DINE_IN,
          action: 'click',
          object: EAnalyticsEventNames.ORDER_LOCKED,
          eventMetadata: {
            initiator: savedDineInState?.guest,
            dineInType: merchant?.dineinPayLaterEnabled ? 'Pay Later' : 'Pay Now',
          },
        },
      });
    }

    if (!isDinein || (isDinein && !merchant?.dineinPayLaterEnabled)) {
      try {
        const data = await validateOrder({
          orderId: order?.id,
          merchantId: merchant?.merchantId || order?.merchantID,
        });

        if (!isHandleAsapLogicOnServer && orderHasValidationIssues(data)) {
          reset();
          return;
        }
      } catch (err) {
        console.error(err);

        if (err.code === 404) {
          modalDispatch({
            type: 'OPEN_MODAL',
            payload: {
              modalKey: EModalTypes.UNKNOWN_ERROR,
              modalContext: {
                onClose: () => {
                  goToMenu({ shouldClearOrder: true });
                },
              },
            },
          });

          return;
        }

        if (
          isHandleAsapLogicOnServer &&
          Object.values(EOrderErrorsCode).some((code) => code === err.code)
        ) {
          return;
        }
      }
    }

    const stringPaymentMethods = paymentMethods.map((payment) => payment.title);
    if (isDinein && merchant?.dineinSplitPaymentEnabled) {
      if (
        order?.groupOrderInfo?.status === EOrderStatus.GROUP_PAYING &&
        router.query.checkoutType !== ECheckoutTypes.SPLIT_BY_AMOUNT
      ) {
        goToCheckoutPage({ checkoutType: ECheckoutTypes.SPLIT_BY_AMOUNT });
      } else {
        goToCheckoutOptionsPage();
      }
    } else {
      sendEvent(EAnalyticsEventNames.BEGIN_CHECKOUT, {
        googleAnalytics: {
          ecommerce: {
            menuItems: order?.items,
            value: order?.postDiscountSubtotal,
            paymentTypeAvailable: stringPaymentMethods,
            paymentTypeDefaulted: previousPaymentType,
          },
        },
      });
      goToCheckoutPage();
    }
  };

  const productCount = order?.items?.length;
  const dineInTableIDUpper = merchant?.dineintableidentifier
    ? merchant?.dineintableidentifier.charAt(0).toUpperCase() +
      merchant?.dineintableidentifier.slice(1)
    : 'Table';
  const bagTitle = isDinein
    ? `${orderTypeDetails?.tableNumberRequired ? dineInTableIDUpper : 'Your'} order`
    : 'Your bag';
  const bagButtonText = useMemo(() => {
    if (isDinein) {
      if (merchant?.dineinPayLaterEnabled) {
        if (order?.isReserveOrder && isOrderPending) return 'Pre-order';
        if (hasUnsentItems) {
          if (merchant?.dineinpreauthrequired && !order?.paymentAuth?.cardNumber)
            return 'Start tab & send items';
          return 'Send items';
        }
        return 'Pay the bill';
      } else {
        return 'Pay and order';
      }
    }

    if (productCount) {
      return 'Continue to checkout';
    }

    return 'Back to menu';
  }, [
    isDinein,
    productCount,
    merchant?.dineinPayLaterEnabled,
    order?.isReserveOrder,
    order?.groupOrderInfo?.status,
    hasUnsentItems,
    merchant?.dineinpreauthrequired,
  ]);

  const totalQuantity = useMemo(() => {
    return order?.items?.reduce((a, c) => a + +c.quantity, 0) || 0;
  }, [order?.items]);

  const total = useMemo(() => {
    if (isSplitByAmount) {
      return checkoutState?.guestBalance;
    }

    return calculateOrderTotal(order);
  }, [checkoutState?.guestBalance, isSplitByAmount, order]);

  const open = isLg || bagOpen;

  return {
    order,
    orderLoading,
    open,
    isMd,
    isLg,
    bagTitle,
    bagButtonText,
    productCount,
    total,
    handleOpen,
    handleClose,
    handleToggle,
    goToCheckout,
    routerLoading,
    totalQuantity,
    isValidatingOrder: isValidatingBagBar || isValidatingOrder,
    isSendingItems,
  };
};
