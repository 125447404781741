import { useMerchantGroup, useMerchant } from '@domain/merchants/queries';
import { useMutation, MutationOptions } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useSavedDineInContextState } from '@olo-web/client-state';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useToast, useSendEvent } from '@olo-web/utils/common/hooks';
import { createFriendlyErrorMessage } from '@olo-web/utils/common/functions';
import { EAnalyticsEventNames, EGALocations } from '@olo-web/types/enums';

export const prepForSend = async (
  orderId: string,
  merchantId: string,
  guestId: string
): Promise<IOrder> => {
  try {
    const { data } = await axios.post(
      `/api/merchants/${merchantId}/orders/${orderId}/prepforsend`,
      {
        guestId,
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const usePrepForSend = (options?: MutationOptions<any, AxiosError>) => {
  const { data: group } = useMerchantGroup();
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const savedDineInState = useSavedDineInContextState();
  const updateOrderInCache = useUpdateOrderInCache();
  const { notify } = useToast();
  const merchId = merchant?.merchantId || group?.eGiftCardMerchantId;
  const { sendEvent } = useSendEvent();

  const mutation = useMutation(() => prepForSend(order.id, merchId, savedDineInState?.guest?.id), {
    ...options,
    onSuccess: (order) => {
      const itemsToPreSend = order?.items?.filter((item) => item?.pending);
      const guestsWhoArePreSending = order?.guests?.filter((guest) => {
        if (guest?.items?.find((item) => item?.pending)) return guest;
      });
      updateOrderInCache(order);
      sendEvent(EAnalyticsEventNames.BEGIN_CHECKOUT, {
        googleAnalytics: {
          location: EGALocations.DINE_IN,
          action: 'click',
          object: EAnalyticsEventNames.SIT_FIRE_SEND,
          eventMetadata: {
            initiator: savedDineInState?.guest,
            guestsInfo: order?.guests,
            itemsSent: itemsToPreSend,
            guestsWhoSentItems: guestsWhoArePreSending,
            dineInType: merchant?.dineinPayLaterEnabled ? 'Pay Later' : 'Pay Now',
          },
        },
      });
    },
    onError: (error) => {
      if (merchant?.dineinPayLaterEnabled)
        notify({
          status: 'error',
          description: createFriendlyErrorMessage(error),
          variant: 'left-accent',
          position: 'top',
          isClosable: true,
        });
    },
  });
  return mutation;
};
