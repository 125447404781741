import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useModalDispatch, useSavedDineInContextState } from '@olo-web/client-state';
import { EGALocations, EModalTypes, EAnalyticsEventNames } from '@olo-web/types/enums';
import { useValidateOrder } from '@olo-web/domain/orders/mutations/useValidateOrder';
import { orderHasValidationIssues } from '@olo-web/domain/orders/functions/orderHasValidationIssues';
import { useSendEvent, useGoToMenu, useHandleAsapLogicOnServer } from '@olo-web/utils/common/hooks';
import { usePrepForSend } from '@olo-web/domain/orders/hooks/usePrepForSend';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { useSendItems } from '@olo-web/domain/orders/hooks/useSendItems';

export const useValidateAndSendItems = () => {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const modalDispatch = useModalDispatch();
  const { mutateAsync: validateOrder, isLoading: isValidatingOrder, reset } = useValidateOrder();
  const { mutateAsync: sendItems, isLoading: isSendingItems } = useSendItems();
  const { sendEvent } = useSendEvent({ canRepeat: true });
  const goToMenu = useGoToMenu({ resetOrderStatus: false });
  const savedDineInState = useSavedDineInContextState();
  const { mutateAsync: prepForSend, isLoading: isPreppingToSend } = usePrepForSend();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();
  const isOrderPending = order?.groupOrderInfo?.status === EOrderStatus.PENDING;

  const itemsSent = order?.items?.filter((item) => item?.sentDateTime === null);
  const guestsWithItems = order?.guests?.filter((guest) => {
    if (guest?.items?.find((item) => item?.sentDateTime === null)) return guest;
  });
  return {
    isValidatingOrder,
    isSendingItems: isSendingItems || isPreppingToSend,
    validateAndSendItems: async () => {
      try {
        const data = await validateOrder({
          orderId: order?.id,
          merchantId: merchant?.merchantId || order?.merchantID,
        });

        if (!handleAsapLogicOnServer && orderHasValidationIssues(data)) {
          reset();
          return;
        }
      } catch (err) {
        console.error(err);

        if (err.code === 404) {
          modalDispatch({
            type: 'OPEN_MODAL',
            payload: {
              modalKey: EModalTypes.UNKNOWN_ERROR,
              modalContext: {
                onClose: () => {
                  goToMenu({ shouldClearOrder: true });
                },
              },
            },
          });

          return;
        }
      }
      if (isOrderPending && order?.isReserveOrder) {
        await prepForSend();
        return;
      }

      await sendItems();
      sendEvent(EAnalyticsEventNames.SEND_ITEMS, {
        googleAnalytics: {
          location: EGALocations.DINE_IN,
          action: 'click',
          object: EAnalyticsEventNames.SEND_ITEMS,
          eventMetadata: {
            initiator: savedDineInState?.guest,
            guestsInfo: order?.guests,
            itemsSent: itemsSent,
            guestsWhoSentItems: guestsWithItems,
            dineInType: merchant?.dineinPayLaterEnabled ? 'Pay Later' : 'Pay Now',
          },
        },
      });
    },
  };
};
